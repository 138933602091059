import React, {useState, useEffect, useRef} from 'react';
import s from './MarqueeRow.module.scss';
import {$api} from "../../api/api";
import {useParams} from "react-router-dom";
import {cls} from "../../utils/helpers/classNames";

const MarqueeRow = () => {
    const [isInEnglish, setIsInEnglish] = useState(false)
    const [shelters, setShelters] = useState([])
    const [shelterName, setShelterName] = useState('')
    const [shelterTitle, setShelterTitle] = useState('')
    const [shelterDistance, setShelterDistance] = useState(null)
    const shelterCountRef = useRef(0)
    const cycleCountRef = useRef(0)
    const {publicId, stopId} = useParams();
    useEffect(() => {
        fetchShelters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shelters.length) {
            setShelterName(shelters[0].address)
            setShelterTitle('Найближчі укриття')
            setShelterDistance(shelters[0].dist ? `${shelters[0].dist} м →`: null);
            const interval = setInterval(() => {
                if (shelterCountRef.current < shelters.length - 1) {
                    shelterCountRef.current += 1;
                } else {
                    shelterCountRef.current = 0;
                }

                setShelterName(shelters[shelterCountRef.current][isInEnglish ? 'addressEn' : 'address']);
                const distance = shelters[shelterCountRef.current].dist
                setShelterDistance(distance ? `${distance} ${isInEnglish ? 'm →': 'м →'}`: null);
                setShelterTitle(isInEnglish ? 'Nearby shelters': 'Найближчі укриття')
                cycleCountRef.current += 1;
                if (cycleCountRef.current >= 8) {
                    setIsInEnglish((prev) => !prev);
                    cycleCountRef.current = 0;
                }
            }, 10_000);

            return () => {
                clearInterval(interval);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shelters, isInEnglish])

    async function fetchShelters() {
        try {
            const response = await $api.get('/api/info-hub/shelters/nearby', {
                params: {publicId, stopId}
            })
            setShelters(response.data)
        } catch (e) {
            console.log('error')
        }
    }

    return (
        <div className={s.shelterInfo}>
            <span className={cls('', {[s.shelterTitle]: shelters.length})}>{shelterTitle}</span>
            <div className={cls(s.distance, {[s.distanceHidden]: !shelterDistance})}>{shelterDistance}</div>
            <span className={cls('', {[s.shelterMessage]: shelters.length})}>{shelterName}</span>
        </div>
    )
}

export default MarqueeRow;
