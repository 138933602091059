import React from 'react'
import {useTranslation} from 'react-i18next'
import s from './Table.module.scss'
import {ReactComponent as BusIcon} from "../../assets/icons/bus.svg";
import {ReactComponent as TramIcon} from "../../assets/icons/tram.svg";
import {ReactComponent as TrollIcon} from "../../assets/icons/troll.svg";
import {ReactComponent as SmallBusIcon} from "../../assets/icons/small-bus.svg";
import {ReactComponent as WheelchairIcon} from "../../assets/icons/wheelchair.svg";
import {cls} from "../../utils/helpers/classNames";
import {TrafficChart} from "../TrafficChart/TrafficChart";


export const TRANSPORT_TYPES = {
    TRAM: 'TRAM',
    TROLLEYBUS: 'TROLLEYBUS',
    BUS: 'BUS',
    SMALL_BUS: 'SMALL_BUS'
}

const TransportIcon = ({transport, mods}) => {
    let icon = null
    let isActive = transport.active
    switch (transport.type) {
        case TRANSPORT_TYPES.TRAM:
            icon = <TramIcon fill={isActive ? '#3F58C5': '#919090'} />
            break;
        case TRANSPORT_TYPES.BUS:
            icon = <BusIcon fill={isActive ?'#E3B360': '#919090'} />
            break;
        case TRANSPORT_TYPES.SMALL_BUS:
            icon = <SmallBusIcon fill={isActive ? "#3AB163": '#919090'} />
            break;
        case TRANSPORT_TYPES.TROLLEYBUS:
            icon = <TrollIcon fill={isActive ? '#C05051': '#919090'} />
            break;
        default:
            icon = <div />
    }

    return <div className={cls(s.iconWrapper, mods)}>
        {icon}
        {transport.inclusive && <div className={cls(s.forDisabled, {[s.notActiveDisabled]: !isActive})}><WheelchairIcon /></div>}
    </div>
}

const Table = ({stopData}) => {
    const {t} = useTranslation()

    function convertSecondsToMinutes(seconds, arrivalTime) {
        if (typeof seconds !== 'number') {
            return '-'
        }

        const minutes = seconds / 60;
        if (minutes >= 60) {
            return arrivalTime
        }

        if (seconds < 60) {
            return `<1${t('min')}`;
        } else {
            return `${Math.round(minutes)}${t('min')}`;
        }
    }

    return (
        <div className={s.Table}>
            <div className={s.tableHeader}>
                <div>{t('type')}</div>
                <div>№</div>
                <div>{t('route')}</div>
                <div>{t('overloading')}</div>
                <div>{t('arrival')}</div>
            </div>
            <div className={s.tableContent}>
                {stopData?.transports.map((transport, index) => {
                    const mods = {
                        [s.pairRows]: index % 2 !== 0,
                        [s.notActive]: !transport.active
                    }
                    const isNear = typeof transport.eta === 'number' && Math.floor(transport.eta / 60) <= 5
                    const timeToStop = convertSecondsToMinutes(transport.eta, transport.arrivalTime)
                    return <div>
                        <TransportIcon mods={mods} transport={transport} />
                        <div className={cls(s.routeId, mods)}>{transport.routeShortName}</div>
                        <div className={cls(s.schemaName, {...mods, [s.widerSchemaName]: !transport.loadData})}>
                            → {transport.destination}
                        </div>
                        {transport.loadData && <div className={cls(s.rowItem, mods)}>
                            <TrafficChart loadData={transport.loadData} />
                        </div>}
                            <div className={cls(s.arrival, {...mods, [s.isNear]: isNear})}>
                                {timeToStop}
                            </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default Table
