import React from 'react';
import s from './AlertMessage.module.scss'
import {ReactComponent as AlarmIcon} from "../../assets/icons/alarm.svg";

const AlertMessage = () => {
    return (
        <div className={s.AlertMessage}>
            <AlarmIcon />
            <span>
                Повітряна тривога! <br/> Негайно в уриття!
            </span>
        </div>
    );
};

export default AlertMessage;
