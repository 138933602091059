// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertMessage_AlertMessage__KX8Kb {
  width: 100%;
  border-radius: 30px;
  background: #FF735C;
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  align-items: center;
  gap: 31px;
}
.AlertMessage_AlertMessage__KX8Kb span {
  color: #000;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 64.4px */
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/AlertMessage/AlertMessage.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EAEA,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AACI;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,WAAA;EACA,yBAAA;AACR","sourcesContent":[".AlertMessage {\n    width: 100%;\n    border-radius: 30px;\n    background: #FF735C;\n    display: flex;\n    //padding: 60px 118px 76px 119px;\n    padding-top: 60px;\n    flex-direction: column;\n    align-items: center;\n    gap: 31px;\n    span {\n        color: #000;\n        text-align: center;\n        leading-trim: both;\n        text-edge: cap;\n        font-family: Inter;\n        font-size: 46px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 140%; /* 64.4px */\n        text-transform: uppercase;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AlertMessage": `AlertMessage_AlertMessage__KX8Kb`
};
export default ___CSS_LOADER_EXPORT___;
